<template>
  <div>
    <div class="footer">
      <span>Contact Us</span>
      <a href="mailto:sadhilanandstudio@gmail.com" >sadhilanandstudio@gmail.com</a>
      <div>Copyright©RE PEAK (HK) CO., LIMITED</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeFooter",
  data() {
      return {}
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/assets/styles/function.scss";

.footer {
  //width: 100%;
  min-height: rem(70px);
  text-align: center;
  background: #f4f9ff;
  font-size: rem(24px);
  font-family: HelveticaNeue;
  line-height: rem(70px);
  text-align: center;
  span,div {
    color: #666666;
  }
  a {
    margin-left: rem(29px);
    color: #333333;
    text-decoration: underline;
  }
}
</style>
